var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['section_banner u--margin-neglg', _vm.info.pagetype == 'home' ? '' : 'section_banner__misc']
  }, [_c('div', {
    staticClass: "section_banner__text"
  }, [_vm.info.pagetype == 'home' ? _c('div', {
    staticClass: "section_banner__text-content u_margin-top-small"
  }, [_c('span', {
    staticClass: "u_adjust-banner-text u_adjust-banner-text-home"
  }, [_vm._v(_vm._s(_vm.info.name))]), _c('p', {
    staticClass: "u_adjust-banner-text_subtitle"
  }, [_vm._v(_vm._s(_vm.info.subtitle))])]) : _c('div', {
    staticClass: "section_banner__text-content"
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v(_vm._s(_vm.info.icon))]), _c('span', {
    staticClass: "u_adjust-banner-text"
  }, [_vm._v(_vm._s(_vm.info.name))])])]), _c('div', {
    staticClass: "section_banner__nav"
  }, [_c('nav', {
    staticClass: "nav_menu"
  }, [_c('ul', [_c('li', {
    staticClass: "u_margin-right-small"
  }, [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("About")]), _c('div', {
    staticClass: "nav_menu--siblings"
  }, [_c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/nm/about"
    }
  }, [_c('a', [_vm._v("About Us")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/nm/how"
    }
  }, [_c('a', [_vm._v("How To")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/nm/news"
    }
  }, [_c('a', [_vm._v("Research + News")])])], 1)])]), _c('li', {
    staticClass: "u_margin-right-small"
  }, [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Visualize")]), _c('div', {
    staticClass: "nav_menu--siblings"
  }, [_c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/explorer"
    }
  }, [_c('a', [_vm._v("Browse Data")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/ns"
    }
  }, [_c('a', [_vm._v("Ontology Explorer")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/explorer/chart"
    }
  }, [_c('a', [_vm._v("Chart Gallery")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/explorer/images"
    }
  }, [_c('a', [_vm._v("Image Gallery")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/explorer/xmls"
    }
  }, [_c('a', [_vm._v("View XMLs")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/explorer/dataset"
    }
  }, [_c('a', [_vm._v("Dataset Gallery")])])], 1)])]), _vm._m(0), _c('li', {
    staticClass: "u_margin-right-small"
  }, [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Tools")]), _c('div', {
    staticClass: "nav_menu--siblings"
  }, [_c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/explorer/sparql"
    }
  }, [_c('a', [_vm._v("Sparql Query")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/explorer/tools"
    }
  }, [_c('a', [_vm._v("Module & Simulation Tools")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/nm/tools/plot-curation"
    }
  }, [_c('a', [_vm._v("Easy CSV Plotter")])]), _vm._m(1)], 1)])]), _c('li', {
    staticClass: "u_margin-right-small"
  }, [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Conferences")]), _c('div', {
    staticClass: "nav_menu--siblings"
  }, [_c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/nm/mrs2022"
    }
  }, [_c('a', [_vm._v("MRS 2022")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/nm/cssi2023"
    }
  }, [_c('a', [_vm._v("CSSI 2023")])]), _c('router-link', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "to": "/nm/2024-doe-poster"
    }
  }, [_c('a', [_vm._v("2024 DOE Poster")])])], 1)])]), _vm.isAuth ? _c('li', {
    staticClass: "u_margin-right-small"
  }, [!_vm.isAdmin ? _c('div', {
    staticClass: "nav_menu--container"
  }, [_c('router-link', {
    staticClass: "u--default-size",
    attrs: {
      "to": "/portal/user"
    }
  }, [_c('span', [_vm._v("My Portal")])])], 1) : _c('div', {
    staticClass: "nav_menu--container"
  }, [_c('router-link', {
    staticClass: "u--default-size",
    attrs: {
      "to": "/portal"
    }
  }, [_c('span', [_vm._v("Admin Center")])])], 1)]) : _vm._e()])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "u_margin-right-small"
  }, [_c('div', {
    staticClass: "nav_menu--container"
  }, [_c('a', {
    staticClass: "u--default-size nav_menu--handler",
    attrs: {
      "href": "/explorer/curate"
    }
  }, [_vm._v("Curate")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "nav_menu--siblings-lists",
    attrs: {
      "href": "/api/api-docs/"
    }
  }, [_c('a', [_vm._v("API Documentation")])]);

}]

export { render, staticRenderFns }